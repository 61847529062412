import {ApiAddress, CustomField, FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService, MinimumOrderErrorData} from '../services/CheckoutService';
import {CheckoutStoreProps} from '../../types/app.types';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {NavigationService} from '../services/NavigationService';
import {BIService} from '../services/BIService';
import {FlowType, StepName} from '../utils/bi.util';
import {AddressModel} from '../models/Address.model';
import {isSubdivisionValidForCountry} from '../utils/localeDataset.util';
import {
  PolicyButtonLocation,
  PolicyType,
} from '../../components/Checkout/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {FedopsInteractions, SPECS} from '../../components/Checkout/constants';
import {ErrorType} from '../utils/errors';
import {ItemTypePreset} from '../models/ItemType.model';

export type CheckoutStoreConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  checkoutService: CheckoutService;
  updateComponent: () => void;
  checkoutSettingsService: CheckoutSettingsService;
  navigationService: NavigationService;
  biService: BIService;
};

export class CheckoutStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly updateComponent: () => void;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly biService: BIService;
  private readonly isFastFlow!: boolean;
  public readonly isPickupFlow!: boolean;
  private isFirstStepCompleted: boolean = false;
  private stepName: string | null = '';

  constructor({
    flowAPI,
    siteStore,
    checkoutService,
    updateComponent,
    checkoutSettingsService,
    navigationService,
    biService,
  }: CheckoutStoreConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    this.checkoutSettingsService = checkoutSettingsService;
    this.navigationService = navigationService;
    this.biService = biService;
    this.isFastFlow = Boolean(navigationService.appSectionParams?.cashierPaymentId);
    this.isPickupFlow =
      siteStore.experiments.enabled(SPECS.UseNewCheckoutInVisitorPickup) &&
      Boolean(navigationService.appSectionParams?.isPickupFlow);
  }

  private readonly applyCoupon = async (couponCode: string): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.ApplyCouponInteraction);
    this.biService.clickApplyCoupon(couponCode, this.checkoutService.checkout);
    await this.checkoutService.applyCoupon(couponCode);
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.ApplyCouponInteraction);
  };

  private readonly removeCoupon = async (): Promise<void> => {
    await this.checkoutService.removeCoupon();
    this.updateComponent();
  };

  private readonly onAddCouponSectionOpen = (): void => {
    this.biService.clickToAddCoupon(this.checkoutService.checkout);
  };

  private readonly onAddGiftCardSectionOpen = (): void => {
    this.biService.giftCardCheckoutClickOnCheckbox(this.checkoutService.checkout);
  };

  private readonly applyGiftCard = async (giftCardCode: string): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.ApplyGiftCardInteraction);
    this.biService.giftCardCheckoutClickApply(this.checkoutService.checkout);
    await this.checkoutService.applyGiftCard(giftCardCode);
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.ApplyGiftCardInteraction);
  };

  private readonly removeGiftCard = async (): Promise<void> => {
    await this.checkoutService.removeGiftCard();
    this.updateComponent();
  };

  private readonly getCustomFieldData = (customFieldValue: string): CustomField => {
    const {customField: customFieldSettings} = this.checkoutSettingsService.checkoutSettings;
    return {
      title: customFieldSettings?.untranslatedTitle,
      value: customFieldValue,
      translatedTitle: customFieldSettings?.title,
    };
  };

  private readonly setCustomField = async (customFieldValue: string): Promise<void> => {
    const customField: CustomField = this.getCustomFieldData(customFieldValue);
    await this.checkoutService.setCustomField(customField);
    this.updateComponent();
  };

  /* istanbul ignore next: test forms */
  private readonly setShippingContactDetails = async ({
    contactDetails,
    email,
    customFieldValue,
  }: {
    contactDetails: FullAddressContactDetails;
    email: string;
    customFieldValue?: string;
  }): Promise<void> => {
    const customField = customFieldValue ? this.getCustomFieldData(customFieldValue) : undefined;
    await this.checkoutService.setShippingInfoContact({
      contactDetails,
      email,
      customField,
    });
    this.updateComponent();
  };

  /* istanbul ignore next: test forms */
  private readonly setBillingContactDetails = async (contactDetails: FullAddressContactDetails): Promise<void> => {
    await this.checkoutService.setBillingContact(contactDetails);
  };

  private readonly setZipCode = async (zipCodeValue: string): Promise<void> => {
    const {billingInfo, shippingDestination} = this.checkoutService.checkout;

    if (this.checkoutService.checkout.hasShippableItems) {
      const address: ApiAddress = {
        ...shippingDestination?.address,
        postalCode: zipCodeValue,
      };

      this.biService.shippingAddressSet(this.checkoutService.checkout, true, true);
      await this.checkoutService.setSingleAddress(address);

      if (this.checkoutService.checkout.shippingOptions.length === 0) {
        this.biService.sendCantShipToDestinationBIEvent(
          this,
          this.checkoutService.checkout,
          this.checkoutSettingsService.checkoutSettings,
          this.navigationService,
          FlowType.fastFlow
        );
      }
    } else {
      const billingAddress: ApiAddress = {
        ...billingInfo?.address,
        postalCode: zipCodeValue,
      };

      await this.checkoutService.setBillingAddress(billingAddress);
    }

    this.updateComponent();
  };

  private readonly setSubdivision = async (subdivisionValue?: string): Promise<void> => {
    const {shippingDestination, billingInfo} = this.checkoutService.checkout;

    if (this.checkoutService.checkout.hasShippableItems) {
      const address: ApiAddress = {
        ...shippingDestination?.address,
        subdivision: subdivisionValue,
      };

      this.biService.shippingAddressSet(this.checkoutService.checkout, true, true);
      await this.checkoutService.setSingleAddress(address);

      if (this.checkoutService.checkout.shippingOptions.length === 0) {
        this.biService.sendCantShipToDestinationBIEvent(
          this,
          this.checkoutService.checkout,
          this.checkoutSettingsService.checkoutSettings,
          this.navigationService,
          FlowType.fastFlow
        );
      }
    } else {
      const billingAddress: ApiAddress = {
        ...billingInfo?.address,
        subdivision: subdivisionValue,
      };

      await this.checkoutService.setBillingAddress(billingAddress);
    }

    this.updateComponent();
  };

  private readonly removeLineItem = async (lineItemId: string): Promise<void> => {
    await this.checkoutService.removeLineItem(lineItemId);
    this.updateComponent();
  };

  private async handleContactSubscription(shouldSubscribe: boolean): Promise<void> {
    if (
      !this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled ||
      !this.checkoutService.checkout.buyerInfo.email
    ) {
      return;
    }
    if (shouldSubscribe) {
      await this.checkoutService.subscribe();

      this.biService.clickPlaceOrderWithSubscription(
        this.checkoutService.checkout,
        this.checkoutSettingsService.checkoutSettings.isSubscriptionCheckedByDefault
      );
    } else {
      await this.checkoutService.unsubscribe();
    }
  }

  private readonly clickPlaceOrderButton = async (shouldSubscribe: boolean): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.PlaceOrderInteraction);
    this.biService.clickPlaceOrder(
      this,
      this.checkoutService.checkout,
      this.checkoutSettingsService,
      this.navigationService,
      FlowType.fastFlow
    );
    void this.handleContactSubscription(shouldSubscribe);

    const orderId = await this.checkoutService.createOrderAndCharge();
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.PlaceOrderInteraction);

    if (this.checkoutService.placeOrderError) {
      await this.checkoutService.fetchCheckout();
      this.updateComponent();
      this.biService.sendFailedToCompleteOrderBIEvent(
        this,
        this.checkoutService.checkout,
        this.checkoutSettingsService.checkoutSettings,
        this.navigationService,
        FlowType.fastFlow,
        {
          stage: StepName.PLACE_ORDER_FAST_FLOW,
          field: this.checkoutService.placeOrderError.code,
          errorMessage: JSON.stringify(this.checkoutService.placeOrderError),
        }
      );
    }
    if (!orderId) {
      return;
    }
    return this.navigationService.navigateToThankYouPage(orderId);
  };

  private readonly isMissingCustomField = (): boolean => {
    return (
      !!this.checkoutSettingsService?.checkoutSettings?.customField?.show &&
      !this.checkoutService.checkout.customField?.value
    );
  };

  private readonly isPlaceOrderButtonDisabled = (): boolean =>
    this.shouldShowSubdivisionSelector() ||
    this.shouldRequireZipCode() ||
    (this.isMissingCustomField() && !!this.checkoutSettingsService.checkoutSettings?.customField?.mandatory) ||
    (this.checkoutService.checkout.hasShippableItems && !this.checkoutService.checkout.selectedShippingOption);

  private readonly getAddress = (): AddressModel | undefined => {
    return this.checkoutService.checkout.hasShippableItems
      ? this.checkoutService.checkout.shippingDestination?.address
      : this.checkoutService.checkout.billingInfo?.address;
  };

  private readonly shouldRequireZipCode = (): boolean => {
    const currentAddress = this.getAddress();
    const postalCode = currentAddress?.postalCode;

    const isShippable = this.checkoutService.checkout.hasShippableItems;

    const canShipToDestination = !!this.checkoutService.checkout.selectedShippingOption;
    const isShippingZipCodeError =
      !postalCode &&
      (!canShipToDestination || !isShippable) &&
      this.checkoutService.checkout.errors.hasShippingZipCodeError;
    const isTaxZipCodeError = this.checkoutService.checkout.errors.hasTaxZipCodeError;

    return !this.shouldShowSubdivisionSelector() && (isShippingZipCodeError || isTaxZipCodeError);
  };

  private readonly shouldShowSubdivisionSelector = (): boolean => {
    const isSubdivisionValid = isSubdivisionValidForCountry(this.getAddress());
    const isShippable = this.checkoutService.checkout.hasShippableItems;

    if (!isShippable) {
      return !isSubdivisionValid;
    }

    const canShipToDestination = !!this.checkoutService.checkout.selectedShippingOption;
    const isShippingSubdivisionError =
      !isSubdivisionValid && !canShipToDestination && this.checkoutService.checkout.errors.hasShippingSubdivisionError;
    const isTaxSubdivisionError = this.checkoutService.checkout.errors.hasTaxSubdivisionError;

    return isShippingSubdivisionError || isTaxSubdivisionError;
  };

  private readonly onPolicyClicked = (linkLocation: PolicyButtonLocation, policyType: PolicyType) => {
    this.biService.checkoutClickOnCheckoutPolicies(this.checkoutService.checkout, linkLocation, policyType);
  };

  private readonly getMinOrderRemainingAmount = () => {
    return Number((this.checkoutService.placeOrderError?.data as MinimumOrderErrorData).remaining.amount);
  };

  private readonly getMinOrderAmount = () => {
    return Number((this.checkoutService.placeOrderError?.data as MinimumOrderErrorData).minimumOrderAmount.amount);
  };

  private readonly hasGiftCardItem = (): boolean =>
    this.checkoutService.checkout.itemTypes.has(ItemTypePreset.GIFT_CARD);

  public readonly shouldShowGiftCardSection = (): boolean => {
    return (
      this.checkoutSettingsService.checkoutSettings.isGiftCardEnabled &&
      this.checkoutSettingsService.checkoutSettings.isGiftCardSupported &&
      !this.checkoutService.checkout.hasSubscriptionItems &&
      !this.hasGiftCardItem()
    );
  };

  public readonly shouldShowCouponSection = (): boolean => {
    return this.checkoutSettingsService.showCouponSP && !this.hasGiftCardItem();
  };

  public readonly sendCheckoutPageLoadBIEvent = (): void => {
    this.biService.checkoutPageLoad(
      this,
      this.checkoutService.checkout,
      this.checkoutSettingsService.checkoutSettings,
      this.navigationService
    );
  };

  public readonly shouldDisplayExpressCheckout = (): boolean => {
    return !this.isFirstStepCompleted;
  };

  public readonly updateStepOnStage = (stepId: number, stepName: string | null): void => {
    if (!this.isFirstStepCompleted && stepId > 0) {
      this.isFirstStepCompleted = true;
    }

    this.stepName = stepName;

    this.updateComponent();
  };

  private readonly onErrorDialogOpened = (): void => {
    if (
      this.checkoutService.placeOrderError &&
      this.checkoutService.placeOrderError?.type === ErrorType.MINIMUM_ORDER_AMOUNT
    ) {
      this.biService.checkoutMinimumOrderModalIsShownInCheckout(
        this.checkoutService.checkout,
        this.getMinOrderRemainingAmount(),
        this.getMinOrderAmount(),
        StepName.PLACE_ORDER_FAST_FLOW
      );
    }
    if (this.checkoutService.checkout.errors.noItemsError) {
      this.biService.sendCheckoutErrorBIEvent(
        this,
        this.checkoutService.checkout,
        this.checkoutSettingsService.checkoutSettings,
        this.navigationService,
        FlowType.fastFlow,
        {
          stage: StepName.ONLOAD_OR_UPDATE_FAST_FLOW,
          field: this.checkoutService.checkout.errors.noItemsError.code,
          errorMessage: JSON.stringify(this.checkoutService.checkout.errors.noItemsError),
        }
      );
    }
  };

  private readonly onErrorDialogClosed = (): void => {
    if (this.checkoutService.placeOrderError) {
      if (this.checkoutService.placeOrderError?.type === ErrorType.MINIMUM_ORDER_AMOUNT) {
        this.biService.checkoutMinimumOrderClickOnGotItInErrorModalInCheckout(
          this.checkoutService.checkout,
          this.getMinOrderRemainingAmount(),
          this.getMinOrderAmount(),
          StepName.PLACE_ORDER_FAST_FLOW
        );
      }
      this.checkoutService.clearPlaceOrderError();
      this.updateComponent();
    }
    if (this.checkoutService.checkout.errors.noItemsError) {
      this.biService.clickOnContinueShopping(this.checkoutService.checkout);
      this.navigationService.navigateToContinueShopping();
    }
  };

  public toProps(): CheckoutStoreProps {
    return {
      checkout: this.checkoutService.checkout,
      ambassadorCheckout: this.checkoutService.ambassadorCheckout,
      placeOrderError: this.checkoutService.placeOrderError,
      applyCouponError: this.checkoutService.applyCouponError,
      applyGiftCardError: this.checkoutService.applyGiftCardError,

      isPlaceOrderButtonDisabled: this.isPlaceOrderButtonDisabled(),
      shouldShowCustomField: this.isMissingCustomField(),
      setShippingContactDetails: this.setShippingContactDetails,
      setBillingContactDetails: this.setBillingContactDetails,
      setZipCode: this.setZipCode,
      setSubdivision: this.setSubdivision,
      shouldRequireZipCode: this.shouldRequireZipCode(),
      shouldShowSubdivisionSelector: this.shouldShowSubdivisionSelector(),
      updateStepOnStage: this.updateStepOnStage,

      isFastFlow: this.isFastFlow,
      isPickupFlow: this.isPickupFlow,

      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      applyGiftCard: this.applyGiftCard,
      removeGiftCard: this.removeGiftCard,
      setCustomField: this.setCustomField,
      removeLineItem: this.removeLineItem,
      clickPlaceOrderButton: this.clickPlaceOrderButton,
      onAddCouponSectionOpen: this.onAddCouponSectionOpen,
      onAddGiftCardSectionOpen: this.onAddGiftCardSectionOpen,
      onErrorDialogOpened: this.onErrorDialogOpened,
      onErrorDialogClosed: this.onErrorDialogClosed,
      onPolicyClicked: this.onPolicyClicked,
      shouldShowGiftCardSection: this.shouldShowGiftCardSection(),
      shouldShowCouponSection: this.shouldShowCouponSection(),
      shouldDisplayExpressCheckout: this.shouldDisplayExpressCheckout(),
    };
  }
}
